<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="text-secondary my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche acteur :
          </span>
          <span
            style="font-size: 25px; color: #343a40"
            class="font-weight-bold"
            >{{ selecteddata.firstname + " " + selecteddata.lastname }}</span
          >
        </p>
        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p
            class="h4 text-secondary my-auto mr-auto"
            style="margin: 6.656px 0 !important"
          >
            Informations générales
          </p>
          <a
            v-if="!editMode && (isAdmin || isSuper)"
            class="text-secondary"
            style="font-size: 30px; color: #007bff !important ; cursor: pointer"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div v-else-if="isAdmin || isSuper" class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateuser"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer
            </b-button>
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler
            </b-button>
          </div>

          <hr class="bg-secondary mb-3 mt-1 w-100" />
        </div>

        <div class="row" v-if="!editMode">
          <div class="col-md-4 col-12">
            <div class="my-4">
              <span>Nom : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.lastname
              }}</span>
            </div>
            <div class="my-4">
              <span>Email : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.email
              }}</span>
            </div>
            <div class="my-4">
              <span>Entité : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.organisationRatachement &&
                selecteddata.organisationRatachement.entite
                  ? selecteddata.organisationRatachement.entite.libelle
                  : "-"
              }}</span>
            </div>
            <div class="my-4">
              <span>Organisation : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.organisationRatachement
                  ? selecteddata.organisationRatachement.libelle
                  : "-"
              }}</span>
            </div>
            <div class="my-4">
              <span>Fonction : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.function
              }}</span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="my-4">
              <span>Prénom : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.firstname
              }}</span>
            </div>
            <div class="my-4">
              <span>Type : </span>
              <span class="font-weight-bold mr-3">{{ selecteddata.type }}</span>
            </div>
            <div class="my-4">
              <span>Niveau hiérarchique : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.hiearchie
              }}</span>
            </div>
            <div class="my-4">
              <span>Statut de compte : </span>
              <span
                class="font-weight-bold mr-3"
                v-if="selecteddata.isActif == true"
                ><span class="badge badge-success">Actif</span>
              </span>
              <span class="font-weight-bold mr-3" v-else
                ><span class="badge badge-danger">Inactif</span>
              </span>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <span>Photo : </span>
            <b-avatar
              :src="`https://dev.api.callstrategy.fr/${selecteddata.avatarUrl}`"
              size="6rem"
            ></b-avatar>
          </div>
        </div>
        <div v-else class="row">
          <!-- First Col -->

          <div class="col-12 col-md-4 pr-0">
            <b-form-group
              label-cols="4"
              label="Nom :"
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newActeur.Nom.$error && $v.newActeur.Nom.$dirty,
                }"
                v-model="newActeur.Nom"
                type="text"
              >
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newActeur.Nom.$dirty"
                >
                  {{ !$v.newActeur.Nom.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Email :"
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newActeur.Email.$error && $v.newActeur.Email.$dirty,
                }"
                type="text"
                v-model="newActeur.Email"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Email.$dirty"
              >
                {{ !$v.newActeur.Email.required ? "Champ obligatoire" : "" }}
                {{ !$v.newActeur.Email.email ? "Invalid email" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Entité :"
              label-class="font-weight-bold"
            >
              <v-select
                label="text"
                :reduce="({ value }) => value"
                v-model="selectedEntite"
                :options="entitesList"
                placeholder="Entité"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.selectedEntite.$error && $v.selectedEntite.$dirty,
                }"
                @input="newActeur.Organisation = null"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedEntite.$dirty"
              >
                {{ !$v.selectedEntite.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Organisation :"
              label-class="font-weight-bold"
            >
              <v-select
                label="text"
                :reduce="(org) => org.value"
                v-model="newActeur.Organisation"
                :options="organisationslist"
                placeholder="Organisation"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newActeur.Organisation.$error &&
                    $v.newActeur.Organisation.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Organisation.$dirty"
              >
                {{
                  !$v.newActeur.Organisation.required ? "Champ obligatoire" : ""
                }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Fonction :"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newActeur.Fonction"
                :class="{
                  'is-invalid':
                    $v.newActeur.Fonction.$error &&
                    $v.newActeur.Fonction.$dirty,
                }"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Fonction.$dirty"
              >
                {{ !$v.newActeur.Fonction.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>

          <!-- Second Col -->
          <div class="col-12 col-md-4 pr-0">
            <b-form-group
              label-cols="4"
              label="Prénom :"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                :class="{
                  'is-invalid':
                    $v.newActeur.Prenom.$error && $v.newActeur.Prenom.$dirty,
                }"
                v-model="newActeur.Prenom"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Prenom.$dirty"
              >
                {{ !$v.newActeur.Prenom.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Type :"
              label-class="font-weight-bold"
            >
              <v-select
                v-model="newActeur.type"
                :options="['Externe', 'Interne']"
                :class="{
                  'is-invalid':
                    $v.newActeur.type.$error && $v.newActeur.type.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.type.$dirty"
              >
                {{ !$v.newActeur.type.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Niveau hiérarchique :"
              label-class="font-weight-bold"
            >
              <v-select
                v-model="newActeur.Niveau"
                :options="fonction"
                style="padding-right: 0px !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newActeur.Niveau.$error && $v.newActeur.Niveau.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>

              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newActeur.Niveau.$dirty"
              >
                {{ !$v.newActeur.Niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <!-- <b-form-group
              label-cols="4"
              label="Statut de compte :"
              label-class="font-weight-bold"
            >
              <b-form-checkbox
                v-model="newActeur.Statut"
                switch
                size="lg"
                variant="success"
              ></b-form-checkbox>
            </b-form-group> -->
          </div>

          <!-- Third Col -->
          <div class="col-12 col-md-4">
            <b-form-group
              label-cols="4"
              label="Photo :"
              label-class="font-weight-bold h-25"
            >
              <drop-zone
                :options="dropzoneOptions"
                :image="selecteddata.avatarUrl ? selecteddata.avatarUrl : ''"
                @change="change"
              ></drop-zone>
            </b-form-group>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="d-flex mb-2">
        <span class="h4 text-secondary mr-auto my-auto"
          >Equipes affectées
        </span>

        <div class="d-flex justify-content-end">
          <b-button
            variant="secondary"
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            :disabled="
              !(isAdmin || isSuper) || (team && team.length ? true : false)
            "
            @click="addNewTeamInputs"
            ><i class="mdi mdi-plus"></i> Affecter équipe
          </b-button>
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
      <div class="row">
        <div class="col-12">
          <b-table
            responsive
            :items="team"
            :fields="affectedTeams.tableFields"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            bordered
            striped
            :busy="loading"
          >
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>

            <template #cell(team_label)="data">
              <b-link
                v-if="!data.item.hasOwnProperty('editMode')"
                :to="{
                  name: 'show-team',
                  params: {
                    slug: data.item.slug ? data.item.slug : '61f1159652a44',
                    id: data.item.id,
                  },
                }"
                >{{ data.value ? data.value : "-" }}</b-link
              >
              <div class="row col-12 mx-auto p-0" v-else>
                <v-select
                  appendToBody
                  label="text"
                  placeholder="Equipe"
                  :reduce="(equipe) => equipe.value"
                  style="min-width: max-content; padding-right: 0 !important"
                  class="p-0 col-12"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.libelle.$error && $v.libelle.$dirty,
                  }"
                  :options="Teams"
                  v-model="libelle"
                  @input="selectEvent"
                >
                </v-select>
                <br />
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.libelle.$dirty"
                >
                  {{ !$v.libelle.required ? "Champ obligatoire" : "" }}
                </span>
              </div>
            </template>
            <template #cell(responsable)="data">
              <TableResponsable
                v-if="!data.item.hasOwnProperty('editMode')"
                :responsable="data.value"
              ></TableResponsable>
              <v-select
                v-else
                label="text"
                :reduce="({ value }) => value"
                :options="responsables"
                v-model="responsable"
                placeholder="Responsable"
                :disabled="!data.item.id ? true : false"
                appendToBody
              ></v-select>
            </template>
            <template #cell(organisation_attachment)="data">
              <b-link
                v-if="!data.item.hasOwnProperty('editMode')"
                :to="{
                  name: 'show-organisation',
                  params: {
                    slug: data.value.slug ? data.value.slug : '61f1159652a44',
                    id: data.value.id,
                  },
                }"
                >{{ data.value ? data.value.libelle : "-" }}</b-link
              >
              <b-form-input
                disabled
                v-model="org"
                v-else
                placeholder="Organisation de rattachement"
              ></b-form-input>
            </template>
            <template #cell(collaborators_number)="data">
              <div
                v-if="!data.item.hasOwnProperty('editMode')"
                class="text-center"
              >
                <b-badge
                  variant="primary"
                  pill
                  @click="
                    showCollaborateur(
                      data.value,
                      data.item.id,
                      data.item.team_label
                    )
                  "
                  style="
                    cursor: pointer;
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                  "
                >
                  {{ data.value.length }}
                </b-badge>
              </div>
              <b-form-input
                v-model="coll"
                v-else
                disabled
                type="number"
                placeholder="Nombre de collaborateurs"
              ></b-form-input>
            </template>
            <template #cell(actions)="data">
              <table-actions
                :actions="isAdmin || isSuper ? actions : []"
                :editMode="data.item.hasOwnProperty('editMode')"
                @cancelItem="removeTeamInputs(data.item.id)"
                @editItem="editNewTeamInputs(data.item, data.item.id)"
                @confirmItem="affecteEquipe(data.item)"
              ></table-actions>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
      </div>
    </b-card>
    <div v-if="organisation" to="modals-xyz-548">
      <Modal @close="organisation = !organisation">
        <CollaborateursList :items="selectedOrganisation" />
      </Modal>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import Modal from "@/components/custom/Modal.vue";
import TableActions from "@/components/custom/TableActions.vue";
import DropZone from "../../../components/custom/DropZone.vue";
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import CollaborateursList from "./components/CollaborateursList";
import TableResponsable from "../../../components/custom/TableResponsable";
import { USER_URL, ORGANISATION_URL } from "@/helpers/constURL";

export default {
  components: {
    TableResponsable,
    CollaborateursList,
    ModalActions,
    TableActions,
    DropZone,
    Modal,
  },
  data: () => ({
    selectedOrganisation: null,
    coll: null,
    org: null,
    responsable: "",
    res: null,
    organisation: false,
    loading: true,
    actions: ["edit"],
    editMode: false,
    remove: null,
    team: null,
    dropzoneOptions: {
      dictDefaultMessage:
        "Déposez une image ici ou cliquez pour sélectionner une image.",
      url: "https://httpbin.org/post",
      thumbnailWidth: 280,
      maxFilesize: 0.5,
      acceptedFiles: ".jpeg,.jpg,.png,.gif",
      maxFiles: 1,
      addRemoveLinks: true,
      parallelUploads: 1,
      removeType: "server",
      removedfile: function (file) {
        var fileName = file.name;
        fileName;
        var _ref;
        return (_ref = file.previewElement) != null
          ? _ref.parentNode.removeChild(file.previewElement)
          : void 0;
      },
    },
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    selecteddata: [],
    newActeur: {
      Nom: "",
      Prenom: "",
      Email: "",
      Organisation: null,
      Fonction: null,
      Niveau: "",
      Statut: false,
      type: "",
    },
    submitingForm: false,
    affectedTeams: {
      tableItems: [
        {
          team_label: "Lorem Ipsum",
          responsable: "Leslie Bechtelar",
          organisation_attachment: "Cadrage",
          collaborators_number: "4",
          actions: ["edit"],
        },
        {
          team_label: "Guillermo Nienow",
          responsable: "Ms. Melissa Fay",
          organisation_attachment: "Cadrage",
          collaborators_number: "6",
          actions: ["edit"],
        },
      ],
      tableFields: [
        { key: "team_label", label: "Libellé équipe", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "collaborators_number",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
    image: null,
    libelle: "",
    selectedEntite: null,
  }),
  validations: {
    selectedEntite: {
      required,
    },
    newActeur: {
      Nom: {
        required,
      },
      Prenom: {
        required,
      },
      Email: {
        required,
        email,
      },
      type: {
        required,
      },
      Fonction: {
        required,
      },
      Organisation: {
        required,
      },
      Niveau: {
        required,
      },
    },
    libelle: {
      required,
    },
  },

  methods: {
    removeThisFile() {
      this.remove = true;
    },
    updateuser() {
      this.$v.$touch();
      if (!this.$v.newActeur.$invalid) {
        var item = {
          id: this.$route.params.id,
          email: this.newActeur.Email,
          firstname: this.newActeur.Prenom,
          lastname: this.newActeur.Nom,
          type: this.newActeur.type,
          // password: "demo",
          isActif: this.newActeur.Statut,
          niveau: this.newActeur.Niveau,
          organisationRatachement: this.newActeur.Organisation
            ? this.newActeur.Organisation
            : [],
          function: this.newActeur.Fonction ? this.newActeur.Fonction : "",
          hiearchie: this.newActeur.Niveau ? this.newActeur.Niveau : "",
          isVerified: true,
        };
        this.editMode = !this.editMode;
        this.$store
          .dispatch("users/updateUser", item)
          .then(() => {
            if (this.image && this.image.dataURL) {
              fetch(this.image.dataURL)
                .then((res) => res.blob())
                .then((blob) => {
                  const fd = new FormData();
                  const file = new File([blob], this.image.name);
                  fd.append("avatar", file);
                  this.$store.dispatch("users/createImage", {
                    id: this.$route.params.id,
                    file: fd,
                  });
                });
            } else {
              if (this.remove == true) {
                this.$store.dispatch("users/updateUser", {
                  id: this.$route.params.id,
                  avatarPath: "",
                  avatarUrl: "",
                });
              }
            }
            this.submitingForm = false;
            this.editMode = !this.editMode;
            Swal.fire({
              title: "L'acteur est mis à jour !",
              type: "success",
              confirmButtonText: "OK",
            });
          })
          .then(() => {
            this.editMode = !this.editMode;
            this.$store.dispatch("users/fetchUser", this.$route.params.id);
          });
      }
    },
    change(files) {
      this.image = files;
    },
    selectEvent: function () {
      this.res = this.TEAMS.filter((data) => data["@id"] == this.libelle);

      this.org = this.res[0].organisation.libelle;
      this.responsable = this.res[0].manager["@id"];
      this.coll = this.res[0].members.length;
    },
    addNewTeamInputs() {
      if (!this.team.length)
        if (this.isAdmin || this.isSuper) {
          var sata = this.team.find((data) => data.editMode === true);
          if (sata == null) {
            this.team.push({ editMode: true });
          }
        }
    },
    editNewTeamInputs(data, index) {
      this.$store.dispatch("teams/fetchAllTeams").then(() => {
        this.team = this.team.map((contributer) =>
          contributer.id === index
            ? { ...contributer, editMode: true, Modify: true }
            : contributer
        );
        this.coll = data.members.length;
        this.org = data.organisation.libelle;
        this.responsable = data.manager["@id"];
        this.libelle = data["@id"];
      });
    },
    removeTeamInputs(itemIndex) {
      if (this.libelle !== "" && this.coll !== "") {
        this.org = "";
        this.responsable = "";
        this.coll = "";
        this.libelle = "";
      } else {
        var sata = this.team.find((data) => data.Modify === true);
        if (sata == null) {
          this.team = this.team.filter((team) => team.id !== itemIndex);
        } else {
          this.$store.dispatch("teams/fetchAllTeams");
        }
      }
    },
    affecteEquipe(item) {
      if (this.libelle && this.responsable)
        this.$store
          .dispatch("users/updateUser", {
            id: this.$route.params.id,
            team: this.libelle,
          })
          .then(() => {
            this.$store
              .dispatch("users/fetchUser", this.$route.params.id)
              .then(() => {
                this.$store.dispatch("teams/fetchAllTeams").then(() => {
                  if (!item.id) {
                    Swal.fire("L'équipe est bien créée !", "", "success");
                  } else {
                    this.$store
                      .dispatch("teams/updateTeam", {
                        id: this.USER.team.id,
                        manager: this.responsable,
                      })
                      .then(() => {
                        this.$store
                          .dispatch("users/fetchUser", this.$route.params.id)
                          .then(() => {
                            Swal.fire(
                              "L'équipe est mise à jour !",
                              "",
                              "success"
                            );
                          });
                      });
                  }
                });
              });
          });
    },
    showCollaborateur(members, id, name) {
      if (members.length > 0) {
        this.$store.dispatch("teams/fetchMembers", id).then(() => {
          members = {
            collaborateur: this.MEMBERS.members.map((data) => ({
              ...data,
              full_name: data.firstname + " " + data.lastname,
              mail: data.email,
              organisation_attachment: this.MEMBERS.organisation,
            })),
            name: name,
          };
          this.selectedOrganisation = members;
          this.organisation = true;
        });
      }
    },
  },

  created() {
    this.$store.dispatch("users/fetchUser", this.$route.params.id).then(() => {
      this.$store.dispatch("loader/toggleLoading", false);
    });
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("users/fetchierachie");
    this.$store.dispatch("users/fetchfunction");
    this.$store.dispatch("teams/fetchAllTeams");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("entity/fetchAllEntities");
  },

  computed: {
    ...mapGetters("users", ["USER", "function", "HIERACHIE", "RESPONSABLES"]),
    ...mapGetters("teams", ["TEAMS", "MEMBERS"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("entity", ["ENTITIES"]),

    rows() {
      return this.team ? this.team.length : 0;
    },
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: `${responsable.firstname} ${responsable.lastname}`,
      })).filter(
        (respo) =>
          !(this.TEAMS.find((team) => team.manager["@id"] === respo.value) ||
          this.TEAMS.find((team) => team.manager["@id"] === respo.value)
            ? this.TEAMS.find((team) => team.manager["@id"] === respo.value)[
                "@id"
              ] === this.libelle
              ? false
              : true
            : false)
      );
    },
    fonction() {
      return this.hierachie;
    },
    Teams() {
      var name = this.team.map((value) => value.name);
      var sata = this.team.find((data) => data.Modify === true);

      if (sata == null) {
        return this.TEAMS.map((data) => ({
          value: data["@id"],
          text: data.name,
        })).filter((data, index) => data.value !== name[index]);
      } else {
        return this.TEAMS.map((data) => ({
          value: data["@id"],
          text: data.name,
        }));
      }
    },
    hierachie() {
      return this.HIERACHIE;
    },
    organisationslist() {
      return this.selectedEntite
        ? this.ORGANISATIONS.filter((organisation) =>
            organisation.entite
              ? this.selectedEntite === organisation.entite["@id"]
              : false
          ).map((org) => ({
            value: `/api/${ORGANISATION_URL}/${org.id}`,
            text: org.libelle,
          }))
        : [];
    },
    entitesList() {
      return this.ENTITIES.filter(
        ({ organisations }) => organisations && organisations.length
      ).map((entity) => ({
        value: entity["@id"],
        text: entity.libelle,
      }));
    },
  },
  watch: {
    USER: function () {
      this.selecteddata = this.USER;
      this.newActeur.Email = this.USER.email;
      this.newActeur.type = this.USER.type;
      this.newActeur.Nom = this.USER.lastname;
      this.newActeur.Prenom = this.USER.firstname;
      this.newActeur.Niveau = this.USER.hiearchie;
      this.newActeur.Fonction = this.USER.function;
      this.newActeur.Statut = this.USER.isActif;
      this.newActeur.Organisation = this.USER.organisationRatachement
        ? this.USER.organisationRatachement["@id"]
        : null;

      this.selectedEntite =
        this.USER.organisationRatachement &&
        this.USER.organisationRatachement.entite
          ? this.USER.organisationRatachement.entite["@id"]
          : null;
      this.loadingData = false;
    },
    TEAMS() {
      this.team = this.TEAMS.map((team) => ({
        ...team,
        team_label: team.name,
        responsable: team.manager,
        organisation_attachment: team.organisation,
        collaborators_number: team.members,
      })).filter((value) =>
        value.members.some(
          (data) => data.id === parseInt(this.$route.params.id)
        )
      );
      this.loading = false;
    },
  },
};
</script>

<style>
@media (min-width: 600px) {
  .align-btn-table-equipe {
    position: relative;
    left: -70px;
  }
}
</style>
