<template>
  <div style="margin-top: -50px">
    <div class="col-10 p-0">
      <p class="h2 text-secondary p-0">
        <i
          style="font-size: 22px; color: #072648 !important"
          class="mdi mdi-domain"
        ></i>
        <span style="font-size: 18px" class="font-weight-light">
          Les collaborateurs de l"équipe :
        </span>
        <span style="font-size: 22px" class="font-weight-bold">
          {{ items.name }}
        </span>

        <span style="font-size: 22px"></span>
      </p>
    </div>
    <div class="col-md-12 p-0"><hr /></div>
    <b-table
      responsive
      :items="items.collaborateur"
      :fields="legalEntity.tableFields"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(full_name)="data">
        <span>{{ data.value }}</span>
      </template>
      <template v-slot:cell(mail)="data">
        <span>{{ data.value }}</span>
      </template>
      <template v-slot:cell(organisation_attachment)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-organisation',
            params: {
              slug: data.item.slug ? data.item.slug : '61f1159652a44',
              id: data.item.id,
            },
          }"
          >{{ data.value ? data.value.libelle : "-" }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template v-slot:cell(function)="data">
        <span>{{ data.value ? data.value : "-" }}</span>
      </template>
      <template v-slot:cell(isActif)="data">
        <span
          v-if="data.value == true"
          style="color: #ffffff"
          class="badge bg-success"
          >Actif</span
        >
        <span v-else class="badge bg-danger" style="color: #ffffff"
          >Inactif</span
        >
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import SortedTablePlugin from "vue-sorted-table";
import Vue from "vue";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});
export default {
  components: {},
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    legalEntity: {
      tableFields: [
        { key: "full_name", label: "Nom complet", sortable: true },
        { key: "mail", label: "Mail", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "function", label: "Fonction", sortable: true },
        { key: "isActif", label: "Compte", sortable: true },
      ],
      tableItems: [
        {
          team_label: "Lorem 1",
          responsable: {
            name: "S.E",
            full_name: "Steve EWORE",
            img: "https://source.unsplash.com/random/150x150",
          },
          organisation_attachment: "Cadrage",
          collaborators_number: 2,
          actions: ["show", "edit", "delete"],
        },
        {
          team_label: "Lorem 2",
          responsable: {
            name: "S.E",
            full_name: "Steve EWORE",
            img: "https://source.unsplash.com/random/150x150",
          },
          organisation_attachment: "Cadrage",
          collaborators_number: 6,
          actions: ["show", "edit", "delete"],
        },
      ],
    },
  }),
  computed: {
    rows() {
      return this.items.length;
    },
    members() {
      return this.items;
    },
  },
  props: {
    items: Array,
  },
  whatch: {},
};
</script>

<style>
</style>
