var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-50px"}},[_c('div',{staticClass:"col-10 p-0"},[_c('p',{staticClass:"h2 text-secondary p-0"},[_c('i',{staticClass:"mdi mdi-domain",staticStyle:{"font-size":"22px","color":"#072648 !important"}}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"18px"}},[_vm._v(" Les collaborateurs de l\"équipe : ")]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.items.name)+" ")]),_c('span',{staticStyle:{"font-size":"22px"}})])]),_vm._m(0),_c('b-table',{attrs:{"responsive":"","items":_vm.items.collaborateur,"fields":_vm.legalEntity.tableFields,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"cell(full_name)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(mail)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(organisation_attachment)",fn:function(data){return [(data.value)?_c('b-link',{attrs:{"to":{
          name: 'show-organisation',
          params: {
            slug: data.item.slug ? data.item.slug : '61f1159652a44',
            id: data.item.id,
          },
        }}},[_vm._v(_vm._s(data.value ? data.value.libelle : "-"))]):_c('span',[_vm._v("-")])]}},{key:"cell(function)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.value ? data.value : "-"))])]}},{key:"cell(isActif)",fn:function(data){return [(data.value == true)?_c('span',{staticClass:"badge bg-success",staticStyle:{"color":"#ffffff"}},[_vm._v("Actif")]):_c('span',{staticClass:"badge bg-danger",staticStyle:{"color":"#ffffff"}},[_vm._v("Inactif")])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 p-0"},[_c('hr')])
}]

export { render, staticRenderFns }